import React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import containerClasses from "../styles/container.module.css"

const TermsPage = () => (
  <Layout>
    <SEO
      title="Terms And Conditions Of Use - Miss Elizabeth Hawkes"
      description="Miss Elizabeth Hawkes – Terms and conditions of use"
    />
    <div className={`${containerClasses.container} py-30`}>
      <div className="col-span-4 md:col-span-8 lg:col-start-2 lg:col-span-10">
        <h1 className="fluid-heading-04">Terms and conditions of use</h1>
        <h2 className="pt-4 fluid-heading-03">Credit</h2>
        This document was created using a template from SEQ Legal (
        <Link to="https://seqlegal.com" className="text-secondaryBrand-9">
          https://seqlegal.com
        </Link>
        ).
        <h2 className="pt-4 fluid-heading-03">Medical disclaimer</h2>
        <h3 className="pt-4 text-xl">(1) Information on this site</h3>
        <p>
          This website contains general information about medical conditions and
          treatments. The information is not advice, and should not be treated
          as such.
        </p>
        <p>
          The medical information on this website is provided without any
          representations or warranties, express or implied. We make no
          representations or warranties in relation to the medical information
          on this website.
        </p>
        <p>
          Without prejudice to the generality of the foregoing paragraph, we do
          not warrant that:
        </p>
        <p>
          (a) the medical information on this website will be constantly
          available, or available at all; or
        </p>
        <p>
          (b) the medical information on this website is complete, true,
          accurate, up-to-date, or non- misleading.
        </p>
        <h3 className="pt-4 text-xl">(2) Professional assistance</h3>
        <p>
          You must not rely on the information on this website as an alternative
          to medical advice from your doctor or other professional healthcare
          provider. If you have any specific questions about any medical matter
          you should consult your doctor or other professional healthcare
          provider. If you think you may be suffering from any medical condition
          you should seek immediate medical attention. You should never delay
          seeking medical advice, disregard medical advice, or discontinue
          medical treatment because of information on this website.
        </p>
        <h3 className="pt-4 text-xl">(3) Our liability</h3>
        <p>Nothing in this medical disclaimer will:</p>
        <p>
          (a) limit or exclude our liability for death or personal injury
          resulting from negligence;
        </p>
        <p>
          (b) limit or exclude our liability for fraud or fraudulent
          misrepresentation;
        </p>
        <p>
          (c) limit any of our liabilities in any way that is not permitted
          under applicable law; or
        </p>
        <p>
          (d) exclude any of our liabilities that may not be excluded under
          applicable law.
        </p>
        <h2 className="pt-4 fluid-heading-03">
          Terms &amp; conditions of use of this website
        </h2>
        <h3 className="pt-4 text-xl">(1) Introduction</h3>
        <p>
          These terms and conditions govern your use of our website
          (www.drelizabethhawkes.com); by using our website, you accept these
          terms and conditions in full. If you disagree with these terms and
          conditions or any part of these terms and conditions, you must not use
          our website.  Our website uses cookies. By using our website and
          agreeing to these terms and conditions, you consent to our use of
          cookies in accordance with the terms of our {" "}
          <Link to="/privacy-policy">Privacy Policy</Link> .
        </p>
        <h3 className="pt-4 text-xl">(2) Licence to use website</h3>
        <p>
          Unless otherwise stated, we or our licensors own the intellectual
          property rights in the website and material on the website. Subject to
          the licence below, all these intellectual property rights are
          reserved. 
        </p>
        <p>
          You may view, download for caching purposes only, and print pages from
          the website for your own personal use, subject to the restrictions set
          out below and elsewhere in these terms and conditions. 
        </p>
        <p>You must not: </p>
        <p>
          (a) republish material from this website (including republication on
          another website); 
        </p>
        <p>(b) sell, rent or sub-license material from the website; </p>
        <p>(c) show any material from the website in public; </p>
        <p>
          (d) reproduce, duplicate, copy or otherwise exploit material on our
          website for a commercial purpose; 
        </p>
        <p>(e) edit or otherwise modify any material on the website; or </p>
        <p>
          (f) redistribute material from this website except for content
          specifically and expressly made available for redistribution.
        </p>
        <h3 className="pt-4 text-xl">(3) Acceptable use</h3>
        <p>
          You must not use our website in any way that causes, or may cause,
          damage to the website or impairment of the availability or
          accessibility of the website; or in any way which is unlawful,
          illegal, fraudulent or harmful, or in connection with any unlawful,
          illegal, fraudulent or harmful purpose or activity.
        </p>
        <p>
          You must not use our website to copy, store, host, transmit, send,
          use, publish or distribute any material which consists of (or is
          linked to) any spyware, computer virus, Trojan horse, worm, keystroke
          logger, rootkit or other malicious computer software.
        </p>
        <p>
          You must not conduct any systematic or automated data collection
          activities (including without limitation scraping, data mining, data
          extraction and data harvesting) on or in relation to our website
          without our express written consent.
        </p>
        <p>
          You must not use our website to transmit or send unsolicited
          commercial communications.  You must not use our website for any
          purposes related to marketing without our express written consent.
        </p>
        <h3 className="pt-4 text-xl">(4) Limited warranties</h3>
        <p>
          We do not warrant the completeness or accuracy of the information
          published on this website; nor do we commit to ensuring that the
          website remains available or that the material on the website is kept
          up-to-date.
        </p>
        <p>
          To the maximum extent permitted by applicable law we exclude all
          representations, warranties and conditions relating to this website
          and the use of this website (including, without limitation, any
          warranties implied by law of satisfactory quality, fitness for purpose
          and/or the use of reasonable care and skill).
        </p>
        <h3 className="pt-4 text-xl">
          (5) Limitations and exclusions of liability
        </h3>
        <p>
          Nothing in these terms and conditions will: (a) limit or exclude our
          or your liability for death or personal injury resulting from
          negligence; (b) limit or exclude our or your liability for fraud or
          fraudulent misrepresentation; (c) limit any of our or your liabilities
          in any way that is not permitted under applicable law; or (d) exclude
          any of our or your liabilities that may not be excluded under
          applicable law.
        </p>
        <p>
          The limitations and exclusions of liability set out in this Section
          and elsewhere in these terms and conditions: (a) are subject to the
          preceding paragraph; and (b) govern all liabilities arising under the
          terms and conditions or in relation to the subject matter of the terms
          and conditions, including liabilities arising in contract, in tort
          (including negligence) and for breach of statutory duty.
        </p>
        <p>
          To the extent that the website and the information and services on the
          website are provided free-of-charge, we will not be liable for any
          loss or damage of any nature.  We will not be liable to you in respect
          of any losses arising out of any event or events beyond our reasonable
          control.
        </p>
        <p>
          We will not be liable to you in respect of any business losses,
          including (without limitation) loss of or damage to profits, income,
          revenue, use, production, anticipated savings, business, contracts,
          commercial opportunities or goodwill.
        </p>
        <p>
          We will not be liable to you in respect of any loss or corruption of
          any data, database or software.
        </p>
        <p>
          We will not be liable to you in respect of any special, indirect or
          consequential loss or damage.
        </p>
        <h3 className="pt-4 text-xl">(6) Indemnity</h3>
        <p>
          You hereby indemnify us and undertake to keep us indemnified against
          any losses, damages, costs, liabilities and expenses (including
          without limitation legal expenses and any amounts paid by us to a
          third party in settlement of a claim or dispute on the advice of our
          legal advisers) incurred or suffered by us arising out of any breach
          by you of any provision of these terms and conditions, or arising out
          of any claim that you have breached any provision of these terms and
          conditions.
        </p>
        <h3 className="pt-4 text-xl">
          (7) Breaches of these terms and conditions
        </h3>
        <p>
          Without prejudice to our other rights under these terms and
          conditions, if you breach these terms and conditions in any way, we
          may take such action as we deem appropriate to deal with the breach,
          including suspending your access to the website, prohibiting you from
          accessing the website, blocking computers using your IP address from
          accessing the website, contacting your internet service provider to
          request that they block your access to the website and/or bringing
          court proceedings against you.
        </p>
        <h3 className="pt-4 text-xl">(8) Variation</h3>
        <p>
          We may revise these terms and conditions from time-to-time. Revised
          terms and conditions will apply to the use of our website from the
          date of the publication of the revised terms and conditions on our
          website. Please check this page regularly to ensure you are familiar
          with the current version.
        </p>
        <h3 className="pt-4 text-xl">(9) Assignment</h3>
        <p>
          We may transfer, sub-contract or otherwise deal with our rights and/or
          obligations under these terms and conditions without notifying you or
          obtaining your consent.
        </p>
        <p>
          You may not transfer, sub-contract or otherwise deal with your rights
          and/or obligations under these terms and conditions.
        </p>
        <h3 className="text-xl pt4">(10) Severability</h3>
        <p>
          If a provision of these terms and conditions is determined by any
          court or other competent authority to be unlawful and/or
          unenforceable, the other provisions will continue in effect. If any
          unlawful and/or unenforceable provision would be lawful or enforceable
          if part of it were deleted, that part will be deemed to be deleted,
          and the rest of the provision will continue in effect
        </p>
        <h3 className="pt-4 text-xl">(11) Exclusion of third party rights</h3>
        <p>
          These terms and conditions are for the benefit of you and us, and are
          not intended to benefit any third party or be enforceable by any third
          party. The exercise of our and your rights in relation to these terms
          and conditions is not subject to the consent of any third party.
        </p>
        <h3 className="pt-4 text-xl">(12) Entire agreement</h3>
        <p>
          These terms and conditions, together with our 
          <Link to="/privacy-policy">Privacy Policy</Link>, constitute the
          entire agreement between you and us in relation to your use of our
          website, and supersede all previous agreements in respect of your use
          of this website.
        </p>
        <h3 className="pt-4 text-xl">​ (13) Law and jurisdiction</h3>
        <p>
          These terms and conditions will be governed by and construed in
          accordance with English law, and any disputes relating to these terms
          and conditions will be subject to the exclusive jurisdiction of the
          courts of England and Wales.
        </p>
        <h3 className="pt-4 text-xl">(14) Our details:</h3>
        <p>
          Registered address: Harley Street Eyesthetics Ltd, c/o Wellington
          House, 273-275 High Street, London Colney, Hertfordshire, United
          Kingdom, AL2 1HA
        </p>
      </div>
    </div>
  </Layout>
)

export default TermsPage
